<template>
  <div id="event-v2">
    <div class="mask-events-container" :class="`${showEventStatus ? 'mask-events-animation-translate-normal':'mask-events-animation-translate-offset'}`">
    <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></b-loading>
      <div class="event-v2-container">
        <div class="event-v2-container__tab-container">
          <div class="event-v2-container__tab-container__tab" @click="() => $emit('actionModalEvents', false)">
            <span>{{$t('eventsTabClose')}}</span>
          </div>
        </div>
        <div class="event-v2-container__fix">
          <div class="event-v2-container__left-content">
            <h2>{{$t('eventsCalendar')}}</h2>
            <div class="event-v2-container__left-content__calendar-events" v-if="showEventStatus">
              <DateTime
                color1="#000000"
                :editPeople="false"
                color2="#000000"
                colorCalendar="#000000"
                :eventCalendar="true"
                :actionCalendarChangeMonthsFromEvents="true"
                @getEventsFromActualMonth="getAllEvents"
                :disabledCalendarEvents="true"
                @updateDateFromCalendarPrecompro="updateDateFromCalendarPrecompro"
                :dateEventPicker="dateEventPicker"
              />
            </div>
          </div>
          <div class="event-v2-container__right-content">
            <div class="aux-calendar-right-content">
              <div class="container-dates-events-mobile">
                <div class="container-dates-events-mobile--close" @click="() => $emit('actionModalEvents', false)">
                  <i class="icon-mks icon_alert_cl"></i>
                </div>
                <div class="container-dates-events-mobile--left" @click="goToNextPreviousMonth('prev')">
                  <i class="fa fa-angle-left"></i>
                </div>
                <div class="container-dates-events-mobile__preview">
                  <i class="icon-mks icon_calendar"></i>
                  <v-date-picker
                    :masks="{ weekdays: 'WWW' }"
                    class="container-dates-events-mobile__preview__datepicker datepicker-custom-precompro inline-block h-full"
                    ref="calendar"
                    v-model="dateEventPicker"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :attributes="attributes"
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="flex items-center">
                        <div class="container-dates-events-mobile__preview__datepicker__info" @click="togglePopover()">
                          <div class="container-dates-events-mobile__preview__datepicker__info__date" v-if="dateEventPicker">{{getDateConverted(inputValue)}}</div>
                          <span class="container-dates-events-mobile__preview__datepicker__info--click">Click para ver todas las fechas</span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </div>
                <div class="container-dates-events-mobile--right" @click="goToNextPreviousMonth('next')">
                  <i class="fa fa-angle-right"></i>
                </div>
              </div>
            </div>
            <h2 class="pc-tablet-events-title-aux">{{eventsActualMonth.length > 1 ? $t('selectOneOurEvents_2') : $t('selectOneOurEvents_2')}}</h2>
            <h2 class="mobile-events-title-aux">{{$t('selectOneOurEvents_2')}}</h2>
            <div class="event-v2-container__right-content__events" v-if="eventsActualMonth.length > 0">
              <CardEvent :dataEvent="event" v-for="(event, index) in eventsActualMonth" :key="index" @updateLoader="updateLoader"  @actionModalEvents="$emit('actionModalEvents', false)" :type="2"/>
              <div class="fix-mobile-elements-cards" style="width: 295px;"></div>
            </div>
            <div class="event-v2-container__right-content__events" v-else>
              <div class="event-v2-container__right-content__events__no-available-events">
                <h3>{{$t('availabilityEventText')}}<br>{{$t('availabilityEventTextAux')}} <span class="month-event-copy">{{dateEventPicker | moment('timezone', vendorTimezone, 'MMMM')}}</span></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardEvent from '@/components/EventsV2/CardEvent.vue';
import DateTime from '@/components/Select/dateTime';
import { mapGetters } from 'vuex';
import eventTypes from '@/store/types/event';
export default {
  props: ['showEventStatus'],
  components: {
    CardEvent,
    DateTime
  },
  data () {
    return {
      eventsActualMonth: [],
      dateEventPicker: null,
      isLoading: false,
      minDate: new Date(new Date().getFullYear() - 1, 0),
      maxDate: new Date(new Date().getFullYear() + 1, 11),
      attributes: null,
      nextEventMonthFlag: false
    };
  },
  methods: {
    getEventsFromActualMonth (actualDate = this.$moment.tz(this.vendorTimezone).valueOf(), getNextEvents = false) {
      const eventsDate = Object.keys(this.allEvents);
      let arrayEventsActualMonth = [];
      if (getNextEvents) {
        this.isLoading = true;
        this.nextEventMonthFlag = true;
        // trae los eventos próximos del mes en el que hayan
        const monthWithEvents = this.$moment(parseInt(eventsDate[eventsDate.length - 1])).month();
        setTimeout(() => {
          this.dateEventPicker = this.$moment(parseInt(eventsDate[eventsDate.length - 1])).toDate();
          this.$store.commit(eventTypes.mutations.setCurrentMonthGlobal, monthWithEvents);
          this.isLoading = false;
        }, 500);
        if (eventsDate.length > 0) {
          eventsDate.forEach(dateEvent => {
            const dateEventConvert = parseInt(dateEvent);
            if (monthWithEvents === this.$moment(dateEventConvert).month()) {
              arrayEventsActualMonth.push(dateEventConvert);
            }
          });
        }
      } else {
        this.nextEventMonthFlag = true;
        eventsDate.forEach(dateEvent => {
          const dateEventConvert = parseInt(dateEvent);
          if (this.$moment(actualDate).month() === this.$moment(dateEventConvert).month()) {
            arrayEventsActualMonth.push(dateEventConvert);
          }
        });
      }
      const eventsActualMonth = [];
      arrayEventsActualMonth.forEach(actualEvent => {
        this.allEvents[actualEvent].forEach(event => {
          // no sé de donde salé el evento ELPUTO...
          if (event.isActive === 1 && event.displayName !== 'ELPUTO' && event.fecha >= this.$moment().format('YYYY-MM-DD')) {
            eventsActualMonth.push(event);
          }
        });
      });
      eventsActualMonth.sort((a, b) => {
        const dateA = this.$moment(a.fecha, 'YYYY-MM-DD');
        const dateB = this.$moment(b.fecha, 'YYYY-MM-DD');
        return dateA - dateB;
      });
      this.eventsActualMonth = eventsActualMonth;
      if (!getNextEvents) {
        this.isLoading = false;
      }
    },
    nowInitial () {
      let hoy = new Date();
      let dia = hoy.getDate();
      let mes = hoy.getMonth() + 1;
      let anio = hoy.getFullYear();
      let now = String(anio + '/' + mes + '/' + dia);
      return now;
      /* return this.$moment().format('YYYY/MM/DD'); */
    },
    getAllEvents (actualDate = this.$moment.tz(this.vendorTimezone).valueOf(), getNextEvents = false) {
      let vendorId = { vendorId: this.$store.getters['whiteLabel:vendorId'] };
      this.isLoading = true;
      this.$store.dispatch({ type: 'event:allEvents', data: vendorId })
        .then(() => {
          let dateInit = this.nowInitial();
          this.setSelectedDates(
            this.selectedDate,
            dateInit,
            this.allEvents
          );
          this.getEventsFromActualMonth(actualDate, getNextEvents);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getDateConverted (date) {
      const dateMoment = this.$moment(date, 'DD/MM/YYYY').toDate();
      return this.$moment.tz(dateMoment, this.vendorTimezone).format('MMMM YYYY');
    },
    goToNextPreviousMonth (action) {
      if (action === 'next') {
        this.dateEventPicker = this.$moment(this.dateEventPicker).add(1, 'months').toDate();
      } else if (action === 'prev') {
        this.dateEventPicker = this.$moment(this.dateEventPicker).subtract(1, 'months').toDate();
      }
    },
    updateDateFromCalendarPrecompro (date) {
      this.dateEventPicker = date;
    },
    updateLoader (status) {
      this.isLoading = status;
    },
    getStarsForDatePickerMobile () {
      let dates = [];
      Object.keys(this.allEvents).forEach(events => {
        dates.push(this.$moment(parseInt(events)).toDate());
      });
      this.attributes = [
        {
          dot: true,
          dates: dates
        }
      ];
    }
  },
  computed: {
    ...mapGetters({
      event: eventTypes.getters.eventFull,
      allEvents: eventTypes.getters.allEvents
    })
  },
  watch: {
    allEvents () {
      this.getStarsForDatePickerMobile();
    },
    dateEventPicker () {
      this.getAllEvents(this.$moment.tz(this.dateEventPicker, this.vendorTimezone).valueOf());
    },
    showEventStatus () {
      if (this.showEventStatus) {
        document.documentElement.style.overflowY = 'hidden';
        this.getAllEvents(this.$moment.tz(this.dateEventPicker, this.vendorTimezone).valueOf(), true);
      } else {
        document.documentElement.style.overflowY = 'inherit';
      }
    }
  }
};
</script>

<style lang="scss">
  @import "@/assets/styles/Event/_index.scss";
</style>
