<template>
  <b-modal :active.sync="value" :can-cancel="false" id="selectZone">
    <div class="header-option">
      <div class="btn-plane">
        <div class="btn-plane-item" v-show="vendor.urlMap != null && !isShowMap && zoneOption.length > 0" @click="isShowMap = !isShowMap">
          Ver plano
        </div>
      </div>
      <div class="cont_title" v-show="(zone == null || zone == false && !isShowMap) || (zone != null && zone != false && !isShowMap)">{{ $t('availableArea') }}</div>
      <div class="cont_title" v-show="vendor.urlMap != null && isShowMap">{{ $t('availableArea_Map') }}</div>
      <div class="cont_close" v-show="!isShowMap">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" @click="hideModal()">
          <path d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z" fill="black"/>
        </svg>
      </div>
    </div>
    <div class="body_modal" v-show="!isShowMap" id="body_modal_zone">
      <div
        :class="{'not_selected' : (zone !== false && zone.id !== item.id && item.isActive === 1), 'image' : item.imageZone, 'isEleOut': (zone !== false && zone.id === item.id && item.isActive === 1), 'isEle': (zone !== false && zone.id === item.id && item.isActive === 1 && zone.copy || msg != null), 'before': (zone !== false && zone.id == item.id && (zone.copy || msg !== null) && item.isActive === 1), 'inactive': item.isActive === 0}"
        :key="'Zone#selected-' + key"
        :id="'Zone#' + key"
        @click="changeZone(item)"
        class="card_zone"
        v-for="(item, key) in zoneOption"
      >
        <div class="cont_info" v-if="!item.imageZone && item.isActive === 1">
          <div class="content_check">
            <b-checkbox
              :false-value="false"
              :true-value="item.id"
              class="check_zone"
              disabled
              size="is-small"
              style="width: auto !important; margin-top: 5px"
              v-model="zone.id"
            ></b-checkbox>
          </div>
          <div class="title_zone fw600" v-if="(zone == null || zone == false) || (zone != null && zone != false && zone.id !== item.id)">{{ item.displayName }}</div>
          <div class="title_zone fw600" v-if="zone != null && zone != false && zone.copy === null && msg === null">{{ item.displayName }}</div>
          <div class="content_description_mobile" id="content_description_mobile" v-if="zone != null && zone != false && zone.id === item.id && isMobileWidth() && (zone.copy || msg !== null)">
            <div class="title_selected pr-2" :style="{margin: (zone.copy || msg !== null) ? '36px 0px 0px 0px' : '17.5px 0px'}">{{ zone.displayName }}</div>
            <div class="divider_zone_image pr-2" v-if="zone.copy || msg !== null"></div>
            <div class="description_selected pr-2" v-html="zone.copy" v-if="vendor.id != 51"></div>
            <div class="description_selected pr-2" v-html="msg" v-if="msg != null"></div>
          </div>
        </div>
        <div id="description_float" class="content_description content_description_image withoutImage" style="display: flex;" v-if="zone != null && zone != false && zone.id === item.id && !isMobileWidth() && zone.imageZone === null && (zone.copy || msg !== null)">
          <div class="title_selected" :style="{margin: (zone.copy || msg !== null) ? '36px 0px 0px 0px' : '17.5px 0px'}">{{ zone.displayName }}</div>
          <div class="divider_zone_image" v-if="zone.copy || msg !== null"></div>
          <div class="description_selected" v-html="zone.copy" v-if="vendor.id != 51"></div>
          <div class="description_selected" v-html="msg" v-if="msg != null"></div>
        </div>
        <div class="cont_info" v-if="item.imageZone && item.isActive === 1">
          <div :style="'background-image: url(' + item.imageZone +')'" class="cont_image" v-if="(zone == null || zone == false) || (zone != null && zone != false && zone.id !== item.id)">
          </div>
          <div class="content_check">
            <b-checkbox
              :false-value="false"
              :true-value="item.id"
              class="check_zone"
              disabled
              size="is-small"
              style="width: auto !important; margin-top: 5px"
              v-model="zone.id"
            ></b-checkbox>
          </div>
          <div class="title_zone image fw600" v-if="(zone == null || zone == false) || (zone != null && zone != false && zone.id !== item.id)">{{ item.displayName }}</div>
          <div class="content_description_mobile content_description_image_mobile cont_image_full" id="content_description_mobile" :style="'background-image: url(' + item.imageZone +')'" v-if="zone != null && zone != false && zone.id === item.id">
            <div class="title_selected pr-2">{{ zone.displayName }}</div>
            <div class="divider_zone_image pr-2" v-if="zone.copy || msg !== null"></div>
            <div class="description_selected pr-2" v-html="zone.copy" v-if="vendor.id != 51"></div>
            <div class="description_selected pr-2" v-html="msg" v-if="msg != null"></div>
          </div>
        </div>
        <div class="cont_info_not" v-if="!item.imageZone && item.isActive === 0">
          <div class="title_zone inactive fw600">{{ item.displayName }}</div>
          <div class="no_availability fw600">{{ $t('unavailable') }}</div>
        </div>
        <div class="cont_info" v-if="item.imageZone && item.isActive === 0">
          <div
            :style="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(' + item.imageZone +') no-repeat center center/cover;'"
            class="cont_image unavailable"
          >
            <div class="no_availability image fw600">{{ $t('unavailable') }}</div>
          </div>
          <div class="title_zone image fw600">{{ item.displayName }}</div>
        </div>
      </div>
    </div>
    <div class="map_restaurant" v-show="isShowMap">
      <div class="map_restaurant-item" :style="{backgroundImage: 'url(' + vendor.urlMap +')', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}"></div>
    </div>
    <div class="map_restaurant_text" v-show="isShowMap">
      Este plano del restaurante te mostrará la ubicación de las diferentes zonas. Conócelo y elige la que más te guste. Para seleccionar una zona, cierra este plano y haz tu elección en la siguiente ventana.
    </div>
    <div class="content_button">
      <button
        :class="{'disabled' : !zone}"
        :disabled="!zone"
        @click="confirm()"
        class="btn_zone"
        type="submit"
        v-show="!isShowMap"
      >{{ $t('continue') }}</button>
      <button
        @click="isShowMap = !isShowMap"
        class="btn_zone close"
        v-show="isShowMap"
      >{{ $t('close') }}</button>
    </div>
    <b-loading :active.sync="loading" :is-full-page="false" />
  </b-modal>
</template>
<script>
// ================ Libraries =============
import { mapActions } from 'vuex';
// ================ Types  ================
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import eventTypes from '@/store/types/event';

import _ from 'lodash';
import sectionTypes from '@/store/types/section';

export default {
  props: ['prevDateZone', 'value', 'editZone'],
  data () {
    return {
      zoneOption: [],
      active: [],
      inactive: [],
      zone: false,
      copy: null,
      msg: null,
      dayOfWeekZone: null,
      loading: true,
      datePrev: false,
      r: 0,
      p: 0,
      isShowMap: false
    };
  },
  watch: {
    zones () {
      this.zones.forEach(async item => {
        const info = this.blocked.find((i) => i.date === this.$moment(this.datePrev).format('YYYY-MM-DD'));
        if (!item.selectSubZone) {
          if (this.selectedTypeReservation === 'Electrónica' && this.params.vendorId === 149) {
            if (info) {
              if (item.displayName !== 'VIP') {
                let zoneData = {
                  id: item.id,
                  displayName: item.displayName,
                  copy: item.copy,
                  imageZone: item.imageZone,
                  urlMap: item.urlMap,
                  isActive: 1
                };
                this.zoneOption.push(zoneData);
                this.zoneOption.sort((a, b) => {
                  // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                  if (b.isActive !== a.isActive) {
                    return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                  }
                  // Si 'isActive' es igual, ordenamos por 'order'
                  return a.order - b.order;
                });
              }
            } else {
              if (item.isActive) {
                if (item.displayName !== 'VIP') {
                  let d = await this.validateDispo(item.id);
                  let zoneData = {
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone,
                    urlMap: item.urlMap,
                    order: item.order,
                    isActive: d > 0 ? 1 : 0
                  };
                  if (d > 0) {
                    this.zoneOption.push(zoneData);
                    this.zoneOption.sort((a, b) => {
                      // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                      if (b.isActive !== a.isActive) {
                        return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                      }
                      // Si 'isActive' es igual, ordenamos por 'order'
                      return a.order - b.order;
                    });
                  } else {
                    this.zoneOption.push(zoneData);
                    this.zoneOption.sort((a, b) => {
                      // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                      if (b.isActive !== a.isActive) {
                        return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                      }
                      // Si 'isActive' es igual, ordenamos por 'order'
                      return a.order - b.order;
                    });
                  }
                }
              }
            }
          } else {
            if (info) {
              let zoneData = {
                id: item.id,
                displayName: item.displayName,
                copy: item.copy,
                imageZone: item.imageZone,
                urlMap: item.urlMap,
                isActive: 1
              };
              this.zoneOption.push(zoneData);
              this.zoneOption.sort((a, b) => {
                // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                if (b.isActive !== a.isActive) {
                  return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                }
                // Si 'isActive' es igual, ordenamos por 'order'
                return a.order - b.order;
              });
            } else {
              if (item.isActive) {
                let d = await this.validateDispo(item.id);
                let zoneData = {
                  id: item.id,
                  displayName: item.displayName,
                  copy: item.copy,
                  imageZone: item.imageZone,
                  urlMap: item.urlMap,
                  order: item.order,
                  isActive: d > 0 ? 1 : 0
                };
                if (d > 0) {
                  this.zoneOption.push(zoneData);
                  this.zoneOption.sort((a, b) => {
                    // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                    if (b.isActive !== a.isActive) {
                      return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                    }
                    // Si 'isActive' es igual, ordenamos por 'order'
                    return a.order - b.order;
                  });
                } else {
                  this.zoneOption.push(zoneData);
                  this.zoneOption.sort((a, b) => {
                    // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                    if (b.isActive !== a.isActive) {
                      return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                    }
                    // Si 'isActive' es igual, ordenamos por 'order'
                    return a.order - b.order;
                  });
                }
              }
            }
          }
        } else {
          if (item.isActive) {
            await item.subSections.forEach(async i => {
              let param = item.id + '=>' + i.id;
              let d = await this.validateDispo(param);
              let subZoneData = {
                id: item.id + '=>' + i.id,
                displayName: i.title,
                copy: i.copy,
                imageZone: i.imageSubZone,
                urlMap: i.urlMap || null,
                order: item.order,
                isActive: d > 0 ? 1 : 0
              };
              if (d > 0) {
                this.zoneOption.push(subZoneData);
                this.zoneOption.sort((a, b) => {
                  // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                  if (b.isActive !== a.isActive) {
                    return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                  }
                  // Si 'isActive' es igual, ordenamos por 'order'
                  return a.order - b.order;
                });
              } else {
                this.zoneOption.push(subZoneData);
                this.zoneOption.sort((a, b) => {
                  // Primero, ordenamos por 'isActive' (1 primero, luego 0)
                  if (b.isActive !== a.isActive) {
                    return b.isActive - a.isActive; // Coloca los activos (isActive = 1) primero
                  }
                  // Si 'isActive' es igual, ordenamos por 'order'
                  return a.order - b.order;
                });
              }
            });
          }
        }
      });
      this.loading = false;
    },
    zone () {
      this.dayOfWeekZone = parseInt(
        this.$moment(this.datePrev)
          .seconds(0)
          .minutes(0)
          .hours(0)
          .milliseconds(0)
          .format('d')
      );
      let zoneVitto = ['112=>57', '112=>58'];
      let id = this.zone.id;
      let exists = _.find(zoneVitto, (data) => {
        if (data === id) {
          return data;
        }
      });
      if (exists === undefined) {
        exists = false;
      } else {
        exists = true;
      }
      // eslint-disable-next-line
      if (this.vendor.id === 51 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 4) && id == '112=>56') {
        this.msg = 'Esta es zona pet friendly.<br> En caso de lluvia tu reserva quedaría en lista de espera y cambiaría para otra zona.<br>Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de tu reserva';
      } else if (this.vendor.id === 51 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 4) && exists) {
        this.msg = 'Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de tu reserva';
        // eslint-disable-next-line
      } else if (this.vendor.id === 51 && (this.dayOfWeekZone !== 5 || this.dayOfWeekZone !== 4) && id == '112=>56') {
        this.msg = 'Esta es zona pet friendly.<br> En caso de lluvia tu reserva quedaría en lista de espera y cambiaría para otra zona.';
      } else if (this.vendor.id === 54 && (this.dayOfWeekZone === 5 || this.dayOfWeekZone === 6 || this.dayOfWeekZone === 4)) {
        this.msg = 'Recuerda que nuestro restaurante tiene dos turnos de reservas por lo que tu mesa podría tener otra reserva dos horas después de la hora de  reservada.';
      } else {
        this.msg = null;
      }
    },
    zoneOption () {
      this.loading = false;
    },
    isShowMap () {
      if (this.isShowMap) {
        if (this.zone) {
          this.changeZone(this.zone);
        }
      }
    }
  },
  mounted () {
    this.datePrev = this.prevDateZone;
    this.getZones({ vendorId: this.vendor.id });
  },
  methods: {
    ...mapActions({
      getZones: sectionTypes.actions.getZones
    }),
    changeZone (item) {
      if (item === this.zone) {
        this.zone = false;
      } else {
        this.zone = item;
      }
      this.selectZone();
      /* const tempArray = { ...this.zoneOption };
      this.zoneOption = tempArray; */
    },
    isMobileWidth () {
      return window.innerWidth <= 767;
    },
    setInfoShow () {
      if (window.innerWidth >= 768 && this.zone.imageZone === null) {
        const totalZone = this.zoneOption.length;
        let items = 4;
        if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
          items = 2;
        }
        const position = (this.zoneOption.findIndex((i) => i.id === this.zone.id) + 1);
        const row = Math.ceil(position / items);
        const totalRows = Math.ceil(totalZone / items);
        const isLastRow = row === totalRows;
        if (isLastRow && (this.zone.copy || this.msg != null)) {
          const bsModal = document.getElementById('Zone#' + (position - 1));
          bsModal.classList.add('isEleImage');
        }
      }
    },
    hideModal () {
      this.zone = false;
      this.copy = null;
      this.msg = null;
      this.$store.commit(reservationTypes.mutations.setSelectedDateTemporal, null);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
      this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, null);
      this.$emit('closeModalZone', true);
      this.$emit('input', false);
      window.localStorage.removeItem('zoneId');
    },
    selectZone () {
      this.copy = this.zone.copy;
      this.$store.commit(
        reservationTypes.mutations.setSelectedZone,
        this.zone.id
      );
      if (this.zone) {
        const sub = Number.isInteger(this.zone.id);
        if (sub) {
          window.localStorage.setItem('zoneId', parseInt(this.zone.id));
        } else {
          if (this.zone.id.indexOf()) {
            const z = this.zone.id.split('=>');
            window.localStorage.setItem('zoneId', parseInt(z[1]));
          }
        }
      } else {
        window.localStorage.removeItem('zoneId');
      }
      this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
      if (this.editZone) {
        this.$emit('changeZone', this.zone.id);
      }
      if (this.zone) {
        setTimeout(() => {
          this.setInfoShow();
        }, 200);
      }
    },
    confirm () {
      this.$emit('input', false);
      this.$emit('hide-select-zone', true);
      // this.$emit('change-date', true)
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      this.$store.commit(reservationTypes.mutations.setSelectedDateTemporal, null);
      let dateInit = this.dateNowInitial();
      let response = this.setSelectedDates(
        this.datePrev,
        dateInit,
        this.allEvents,
        this.zone
      );
      // se utiliza el commit de la variable global isEventDay
      this.$parent.$emit('isEventDay', response.evento);
      this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);
      this.zone = false;
      this.copy = null;
      this.msg = null;
    },
    validateDispo: async function (item) {
      this.p++;
      var obs = this;
      let time = parseInt(new Date(this.datePrev).getTime());
      if (!this.datePrev) {
        time = parseInt(this.$moment().seconds(0).minutes(0).hours(0).milliseconds(0).format('x'));
      }
      await this.$store.dispatch({
        type: 'whiteLabel:avalibilityHourNoCommit',
        data: {
          timestamp: time,
          vendorId: this.$store.getters['whiteLabel:vendorId'],
          people: this.selectedPeople,
          zone: item,
          typeReservation: this.selectedTypeReservation,
          tableId: this.selectedTable
        }
      })
        .then(response => {
          obs.r = 0;
          response.forEach(i => {
            if (i.status === true) {
              obs.r++;
            }
          });
          this.p--;
        });
      return obs.r;
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Select/selectZone.scss";
</style>
