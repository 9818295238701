<template>
  <div class="event-v2-container__right-content__events__card">
    <div class="event-v2-container__right-content__events__card__ribbon" v-if="getPercentCapacity(dataEvent.capacity, dataEvent.capacityTaken)">
      <span>{{$t('lastQuotas')}}</span>
    </div>
    <div class="event-v2-container__right-content__events__card__date-reserve">
      <div class="event-v2-container__right-content__events__card__date-reserve__date">
        <i class="icon-mks icon_calendar"></i>
        <div>{{ parseInt(dataEvent.date) | moment('timezone', vendorTimezone, 'DD MMM') }}</div>
      </div>
      <div v-if="type === 1" class="event-v2-container__right-content__events__card__date-reserve__hours">
        <i class="icon-mks icon_hour_outlined"></i>
        <div>08:00 pm</div>
      </div>
    </div>
    <div class="event-v2-container__right-content__events__card__img">
      <img class="" :src="dataEvent.cardURL" alt="" srcset="" v-if="dataEvent.cardURL">
      <img class="" src="@/assets/images/defaultImgs.png" alt="" srcset="" v-if="!dataEvent.cardURL">
      <div v-if="dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity)) " class="event-v2-container__right-content__events__card__img sold-out-bg">
        <img class="" src="@/assets/images/events/sold_out.svg" alt="" srcset="">
      </div>
    </div>
    <h3 :class="`${type === 2 ? 'fix': ''}`">{{dataEvent.displayName}}</h3>
    <p :class="`${type === 2 ? 'fix_p': ''}`" v-html="dataEvent.description">
    </p>
    <div class="event-v2-container__right-content__events__card__price" v-if="type === 1">
      <div>$50.000 {{$t('perPeople')}}</div>
      <span>{{$t('moreInfo')}}</span>
    </div>
    <div class="event-v2-container__right-content__events__card__price_hour" v-if="type === 2">
      <div class="event-v2-container__right-content__events__card__price_hour__hour">
        <div class="icon-hour-outlined-container">
          <i class="icon-mks icon_hour_outlined"></i>
        </div>
        <div>Horario:</div>
        <div class="event-v2-container__right-content__events__card__price_hour__hour__hours-container"  v-if="dataEvent.times.length > 0">
          <span v-for="(time, index) in dataEvent.times" :key="index">
            <span v-if="time.endDate">De </span>{{ time.starDateAux | moment('timezone', vendorTimezone, 'hh:mm a') }}
            <span v-if="time.endDate"> a {{ time.endDateAux | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
            <span v-if="index >= 0 && index+2 <= dataEvent.times.length"> - </span>
            <!-- <span v-if="index === 1">...</span> -->
          </span>
        </div>
        <div class="event-v2-container__right-content__events__card__price_hour__hour__hours-container" v-else>
          {{$t('noHoursEvent')}}
        </div>
      </div>
      <div class="one-line-ellipsis">{{$t('eventValue')}}
        <b v-if="dataEvent.eventValue">{{formatItemsString(dataEvent.eventValue)}}</b>
        <b v-else>$ 0</b>
      </div>
      <div class="other-value">
        <div>
          {{$t('advanceValue')}}
          <b v-if="dataEvent.purchaseCommission"> {{ dataEvent.purchaseCommission | currency('$ ', 0, { thousandsSeparator: ',' }) }}</b>
          <b v-else>$ 0</b>
        </div>
        <span @click="()=> detailsEvent = true">{{$t('moreInfo')}}</span>
      </div>
    </div>
    <button @click="bookEvent" :disabled="dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity)) ? true: false" :class="`event-v2-container__right-content__events__card--action ${type === 1 ? 'fix': ''} ${dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity)) ? 'event-v2-container__right-content__events__card--action-inactive': ''}`">
      <div v-html="renderContent"></div>
    </button>
    <b-modal :active="detailsEvent" id="detailsEvent">
      <div class="container-detail-event">
        <div class="event-v2-container__right-content__events__card__img">
          <img class="" :src="dataEvent.cardURL" alt="" srcset="" v-if="dataEvent.cardURL">
          <img class="" src="@/assets/images/defaultImgs.png" alt="" srcset="" v-if="!dataEvent.cardURL">
          <div v-if="dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity))" class="event-v2-container__right-content__events__card__img sold-out-bg">
            <img class="" src="@/assets/images/events/sold_out.svg" alt="" srcset="">
          </div>
        </div>
        <h3 :class="``">{{dataEvent.displayName}}</h3>
        <p :class="``" v-html="dataEvent.description"></p>
        <div class="event-v2-container__right-content__events__card__price" v-if="type === 1">
          <div>$50.000 {{$t('perPeople')}}</div>
          <span>{{$t('moreInfo')}}</span>
        </div>
        <div class="event-v2-container__right-content__events__card__price_hour" v-if="type === 2">
          <div class="event-v2-container__right-content__events__card__price_hour__hour" v-if="dataEvent.times.length > 0">
            <i class="icon-mks icon_hour_outlined"></i> {{$t('scheduleEvent')}}
            <div class="event-v2-container__right-content__events__card__price_hour__hour__hours-container-detail">
              <b v-for="(time, index) in dataEvent.times" :key="index">
                <span v-if="time.endDate">De </span>{{ time.starDateAux | moment('timezone', vendorTimezone, 'hh:mm a') }}
                <span v-if="time.endDate"> a {{ time.endDateAux | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
                <span style="margin-right:3px;" v-if="index >= 0 && index+2 <= dataEvent.times.length"> - </span>
              </b>
            </div>
          </div>
          <div class="event-v2-container__right-content__events__card__price_hour__hour__hours-container" v-else>
            {{$t('noHoursEvent')}}
          </div>
          <div class="">{{$t('eventValue')}}
            <b v-if="dataEvent.eventValue"> {{formatItemsString(dataEvent.eventValue)}}</b>
            <b v-else>$ 0</b>
          </div>
          <div class="other-value">
            <div>
              {{$t('advanceValue')}}
              <b v-if="dataEvent.purchaseCommission"> {{ dataEvent.purchaseCommission | currency('$ ', 0, { thousandsSeparator: ',' }) }}</b>
              <b v-else>$ 0</b>
            </div>
          </div>
          <div class="event-v2-container__right-content__events__card__date-reserve__date">
            <i class="icon-mks icon_calendar"></i>
            <div>{{ parseInt(dataEvent.date) | moment('timezone', vendorTimezone, 'DD MMM') }}</div>
          </div>
        </div>
        <div class="container-detail-event--actions">
          <button @click="bookEvent" :disabled="dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity)) ? true: false" :class="`container-detail-event--actions__reserve ${type === 1 ? 'fix': ''} ${dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity)) ? 'container-detail-event--actions__reserve-inactive': ''}`">
            {{ dataEvent.capacity === 0 || (dataEvent.capacity_taken && (parseInt(dataEvent.capacity_taken) > dataEvent.capacity)) ? 'SOLD OUT': $t('bookEvent') }}</button>
          <button class="container-detail-event--actions__close" @click="() => detailsEvent = false">{{$t('closeEvent')}}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import whiteLabelTypes from '@/store/types/whiteLabel';
import eventTypes from '@/store/types/event';
import globalTypes from '@/store/types/global';
import sectionTypes from '@/store/types/section';
import reservationTypes from '@/store/types/reservation';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['type', 'dataEvent'],
  data () {
    return {
      detailsEvent: false,
      defaultImg: '',
      now: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      zone: false,
      zoneOption: [],
      zoneOptionInactive: []
    };
  },
  watch: {
    dataEvent () {
      this.orderTimes();
    },
    zones () {
      this.zones.forEach(async item => {
        const info = this.blocked.find((i) => i.date === this.$moment(this.prevDate).format('YYYY-MM-DD'));
        if (!item.selectSubZone) {
          if (this.selectedTypeReservation === 'Electrónica' && this.params.vendorId === 149) {
            if (info) {
              if (item.displayName !== 'VIP') {
                this.zoneOption.push({
                  id: item.id,
                  displayName: item.displayName,
                  copy: item.copy,
                  imageZone: item.imageZone
                });
              }
            } else {
              if (item.isActive) {
                if (item.displayName !== 'VIP') {
                  let d = await this.validateDispo(item.id);
                  if (d > 0) {
                    this.zoneOption.push({
                      id: item.id,
                      displayName: item.displayName,
                      copy: item.copy,
                      imageZone: item.imageZone,
                      order: item.order
                    });
                    this.zoneOption.sort((a, b) => a.order - b.order);
                  } else {
                    this.zoneOptionInactive.push({
                      id: item.id,
                      displayName: item.displayName,
                      copy: item.copy,
                      imageZone: item.imageZone,
                      order: item.order
                    });
                    this.zoneOptionInactive.sort((a, b) => a.order - b.order);
                  }
                }
              }
            }
          } else {
            if (info) {
              this.zoneOption.push({
                id: item.id,
                displayName: item.displayName,
                copy: item.copy,
                imageZone: item.imageZone
              });
            } else {
              if (item.isActive) {
                let d = await this.validateDispo(item.id);
                if (d > 0) {
                  this.zoneOption.push({
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone,
                    order: item.order
                  });
                  this.zoneOption.sort((a, b) => a.order - b.order);
                } else {
                  this.zoneOptionInactive.push({
                    id: item.id,
                    displayName: item.displayName,
                    copy: item.copy,
                    imageZone: item.imageZone,
                    order: item.order
                  });
                  this.zoneOptionInactive.sort((a, b) => a.order - b.order);
                }
              }
            }
          }
        } else {
          if (item.isActive) {
            await item.subSections.forEach(async i => {
              let param = item.id + '=>' + i.id;
              let d = await this.validateDispo(param);
              if (d > 0) {
                this.zoneOption.push({
                  id: item.id + '=>' + i.id,
                  displayName: i.title,
                  copy: i.copy,
                  imageZone: i.imageSubZone,
                  order: item.order
                });
                this.zoneOption.sort((a, b) => a.order - b.order);
              } else {
                this.zoneOptionInactive.push({
                  id: item.id + '=>' + i.id,
                  displayName: i.title,
                  copy: i.copy,
                  imageZone: i.imageSubZone,
                  order: item.order
                });
                this.zoneOptionInactive.sort((a, b) => a.order - b.order);
              }
            });
          }
        }
      });
      this.loading = false;
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateScreenWidth);
    this.orderTimes();
    this.getZones({ vendorId: this.vendor.id });
  },
  computed: {
    ...mapGetters({
      dateTimeEventGlobal: eventTypes.getters.dateTimeEventGlobal,
      systemDate: globalTypes.getters.serverTime
    }),
    isScreen960 () {
      return this.screenWidth <= 960;
    },
    renderContent () {
      if (this.dataEvent.capacity === 0 || (this.dataEvent.capacity_taken && (parseInt(this.dataEvent.capacity_taken) > this.dataEvent.capacity))) {
        return 'SOLD OUT';
      } else {
        return `<span>★</span>${this.$t('bookInThisEvent')}`;
      }
    }
  },
  methods: {
    ...mapActions({
      getZones: sectionTypes.actions.getZones
    }),
    getTimeEventId (event) {
      this.$store
        .dispatch({
          type: 'event:getTimesEvent',
          data: { eventId: Number(event.eventId) ? this.encrypt(event.eventId) : event.eventId, people: this.selectedPeople }
        })
        .then(response => {
          if (event) {
            // se cambia el eventIdStatic a global
            /*  this.eventIdStatic = event; */
            this.$store.commit(eventTypes.mutations.setEventIdStaticGlobal, event);
          }
          /* this.dateTimeEvent = response.data; */
          this.$store.commit(eventTypes.mutations.setDateTimeEventGlobal, response.data);
          this.$store.commit(eventTypes.mutations.setEventFull, event);
          this.$store.commit(eventTypes.mutations.setEventMinPeople, event.minPeople);

          this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          this.$emit('updateLoader', false);
        });
    },
    bookEvent () {
      this.$emit('updateLoader', true);
      this.selectZone();
      if (this.isScreen960) {
        document.getElementById('selectPeople').style.display = 'none';
        document.getElementById('selectDate').style.display = 'none';
        document.getElementById('selectHour').style.display = 'flex';
        this.$store.commit(whiteLabelTypes.mutations.setSection, 3);
        if (document.getElementById('SwipeActive')) {
          setTimeout(() => {
            document.getElementById('SwipeActive').scrollIntoView();
          }, 100);
        }
      }
    },
    getPercentCapacity (capacity, capacityTaken) {
      const percentQuota = (capacityTaken * 100) / capacity;
      return percentQuota <= 20;
    },
    updateScreenWidth () {
      this.screenWidth = window.innerWidth;
    },
    orderTimes () {
      this.dataEvent.times.forEach(time => {
        time.starDateAux = new Date(parseInt(time.starDate));
        if (time.endDate !== null) {
          time.endDateAux = new Date(parseInt(time.endDate));
        }
      });
      this.dataEvent.times = this.dataEvent.times.sort((a, b) => a.starDate - b.starDate);
    },
    selectZone () {
      if (this.params.isActiveSelectedZone === 1) {
        if (this.zoneOption.length > 0) {
          this.zone = this.zoneOption[0];
          this.$store.commit(
            reservationTypes.mutations.setSelectedZone,
            this.zoneOption[0].id
          );
          if (this.zone) {
            const sub = Number.isInteger(this.zoneOption[0].id);
            if (sub) {
              window.localStorage.setItem('zoneId', parseInt(this.zoneOption[0].id));
            } else {
              if (this.zoneOption[0].id.indexOf()) {
                const z = this.zoneOption[0].id.split('=>');
                window.localStorage.setItem('zoneId', parseInt(z[1]));
              }
            }
          } else {
            window.localStorage.removeItem('zoneId');
          }
          this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
          if (this.editZone) {
            this.$emit('changeZone', this.zoneOption[0].id);
          }
          let dateInit = this.dateNowInitial();
          let response = this.setSelectedDates(parseInt(this.dataEvent.date), dateInit, this.allEvents, this.zone);

          // se utiliza el commit de la variable global isEventDay
          /* this.$parent.$emit('isEventDay', response.evento); */
          this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);
          this.getTimeEventId(this.dataEvent);
        }
        this.zone = false;
      } else {
        let response = this.setSelectedDates(parseInt(this.dataEvent.date), this.systemDate, this.allEvents);
        this.$emit('changeEventDay', response.evento);

        // se utiliza el commit de la variable global isEventDay
        /* this.$parent.$emit('isEventDay', response.evento); */
        this.$store.commit(eventTypes.mutations.setIsEventDayGlobal, response.evento);
        this.getTimeEventId(this.dataEvent);
      }
      this.$emit('actionModalEvents');
      this.$emit('updateLoader', false);
    },
    validateDispo: async function (item) {
      this.p++;
      var obs = this;
      let time = parseInt(new Date(this.datePrev).getTime());
      if (!this.datePrev) {
        time = parseInt(this.$moment().seconds(0).minutes(0).hours(0).milliseconds(0).format('x'));
      }
      await this.$store.dispatch({
        type: 'whiteLabel:avalibilityHourNoCommit',
        data: {
          timestamp: time,
          vendorId: this.$store.getters['whiteLabel:vendorId'],
          people: this.selectedPeople,
          zone: item,
          typeReservation: this.selectedTypeReservation,
          tableId: this.selectedTable
        }
      })
        .then(response => {
          obs.r = 0;
          response.forEach(i => {
            if (i.status === true) {
              obs.r++;
            }
          });
          this.p--;
        });
      return obs.r;
    },
    currency (value, symbol, decimals, options) {
      const { thousandsSeparator } = options;
      let number = parseFloat(value).toFixed(decimals);
      number = number.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
      return symbol + number;
    },
    formatItemsString (string) {
      const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      });
      // Expresión regular para encontrar los números en el string
      const regex = /\d+/g;
      // Encuentra y formatea todos los números en el string
      let matches = string.match(regex);
      if (matches) {
        matches = matches.map(match => {
          const number = parseInt(match, 10);
          // Formatea el número y reemplaza los puntos por comas
          return formatter.format(number).replace(/\./g, ',');
        });
        // Reemplaza los números en el string original con los formateados
        const formattedString = string.replace(regex, () => matches.shift());
        return formattedString;
      }
      return string;
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateScreenWidth);
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/Event/_DetailsEvent.scss";
</style>
