<template>
  <b-modal :active.sync="showDescriptionEvent" v-if="showDescriptionEvent">
    <div
      class="modals-bosy-little"
      v-bind:style="getBackgroundColor(design.backgroundColorPopup, 'back')"
    >
      <div class="black-label" v-bind:style="getBackgroundColor(design.headerPopup)">
        <div
          class="button-circle"
          v-bind:style="getBackgroundColor(design.circleBackgroundPopup, 'btn', design.circleContentColor)"
        >
          <span class="span-text-info">i</span>
        </div>
      </div>
      <div class="Content-modal-text">
        <span
          class="span-text"
          v-bind:style="getBackgroundColor(design.headerPopup, 'p')"
        >{{event.displayName}}</span>
        <p
          class="text-info"
          v-bind:style="getBackgroundColor(design.colorPopup, 'p')"
          v-html="event.description"
        ></p>
      </div>
      <div class="btns-modal">
        <button
          @click="acceptRumba"
          class="full-btn-modal btn-modal-fill button-modal"
          v-bind:style="
            getBackgroundColor(
              design.headerPopup,
              'btn',
              design.colorCheckboxTerms
            )
          "
        >{{ $t('agree') }}</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import eventTypes from '@/store/types/event';
export default {
  methods: {
    acceptRumba () {
      this.$store.commit(eventTypes.mutations.setShowDescriptionEvent, false);
    },
    getBackgroundColor (color, type = null, color2 = null) {
      if (type === 'back') {
        return {
          background: color
        };
      }
      if (type === 'p') {
        return {
          color: color
        };
      }
      if (type === 'btn') {
        return {
          'background-color': color,
          color: color2
        };
      }
      return {
        'background-color': color
      };
    }
  },
  computed: {
    ...mapGetters({
      event: eventTypes.getters.eventFull,
      showDescriptionEvent: eventTypes.getters.showDescriptionEvent
    })
  }
};
</script>
<style lang="scss" scoped>
.not-outline {
  outline: 0px !important;
  box-shadow: none !important;
}
.modals-bosy-little .btns-modal {
  display: flex;
  justify-content: center;
  margin-top: 0px !important;
  outline: 0px !important;
  box-shadow: none !important;
}

.modals-bosy-little {
  padding: 0px 0px 20px !important;
  border-radius: 30px;
}

.modals-bosy-little .btns-modal .full-btn-modal {
  min-width: 30%;
  height: 40px;
  border-radius: 9px;
  font-family: 'Source Sans Pro';
}

p {
  font-size: 16px;
  font-family: 'Source Sans Pro Light';
}

.black-label {
  background: black;
  width: inherit;
  max-width: 480px;
  height: 50px;
  max-height: 85px;
  border-radius: 25px 25px 0px 0px;
  top: 0;
}

.button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 55px;
  min-width: 55px;
  height: 55px;
  min-height: 55px;
  margin-left: 30px;
  border-radius: 50px;
  top: 25px;
  box-shadow: 0px 2px 5px 1px rgba(155, 155, 155, 0.5);
}

.span-text {
  display: flex;
  justify-content: center;
  font-size: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: 'Source Sans Pro';
  font-weight: bold;
  text-align: center;
}

.span-text-info {
  font-family: coonfortaBold;
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}
</style>
